import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./Wipeit(4).png"; // Import your logo image

const Login = ({ setAuthenticated }) => {
  //Login Screen for Stats
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (username && password) {
      try {
        const response = await fetch("https://api.wipe.wtf/stats/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: username,
            password: password,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.accessToken) {
            // Save the access token to local storage
            localStorage.setItem("accessToken", data.accessToken);

            // Set authenticated state to true
            setAuthenticated(true);

            // Navigate to analytics page
            navigate("/Dashboard");
          } else {
            alert("Login failed. Please try again.");
          }
        } else {
          alert("Login failed. Please try again.");
        }
      } catch (error) {
        console.error("Login error:", error);
        alert("Login failed. Please try again later.");
      }
    } else {
      alert("Please enter a valid username and password.");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#0b0b0b",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <img
        src={logo}
        alt="Logo"
        style={{
          width: "100px",
          height: "100px",
          position: "absolute",
          top: "20px",
          left: "30px",
        }}
      />
      <div
        style={{
          textAlign: "center",
          color: "white",
          margin: "auto",
          width: "300px",
          backgroundColor: "rgba(31, 31, 31, 0.8)",
          padding: "55px",
          paddingTop: "60px",
          paddingBottom: "60px",
          borderRadius: "15px",
          backdropFilter: "blur(20px)", // Adjust the blur strength as needed
          boxShadow: "0px 0px 20px rgba(9, 9, 9, 0.17)",
        }}
      >
        <div
          style={{
            textAlign: "left",
            color: "white",
            width: "300px",
            margin: "auto",
          }}
        >
          <div style={{ width: "100%", marginBottom: "20px" }}>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{
                width: "100%",
                padding: "15px",
                borderRadius: "15px",
                border: "1px solid #ccc",
                marginBottom: "10px",
                boxSizing: "border-box", // Ensure the padding and border are included in the width
              }}
            />
          </div>
          <div style={{ width: "100%", marginBottom: "20px" }}>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                width: "100%",
                padding: "15px",
                borderRadius: "15px",
                border: "1px solid #ccc",
                marginBottom: "10px",
                boxSizing: "border-box", // Ensure the padding and border are included in the width
              }}
            />
          </div>
          <div>
            <button
              onClick={handleLogin}
              style={{
                backgroundColor: "#0c0c0c",
                color: "#fff",
                padding: "15px",
                borderRadius: "15px",
                border: "none",
                cursor: "pointer",
                fontWeight: "bold",
                width: "100%",
                boxSizing: "border-box", // Ensure the padding and border are included in the width
              }}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
