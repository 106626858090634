import logo from "./Wipeit(4).png"; // Import your logo image
import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";

const Dashboard = () => {
  const navigate = useNavigate();
  const buttonStyle = {
    backgroundColor: "#1c1c1c",
    color: "#f1f1f1",
    padding: "15px",
    borderRadius: "10px",
    border: "none",
    cursor: "pointer",
    fontWeight: "bold",
    width: "100%",
    marginBottom: "10px", // Space between buttons
    boxSizing: "border-box",
  };
  const [loginData, setLoginData] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const [commentdata, setcommentdata] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    axios
      .get("https://api.wipe.wtf/stats/users", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoginData(response.data);
      })
      .catch((error) => console.error("Error fetching login data:", error));

    axios
      .get("https://api.wipe.wtf/stats/videos", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAccountData(response.data);
      })
      .catch((error) =>
        console.error("Error fetching account creation data:", error)
      );

    axios
      .get("https://api.wipe.wtf/stats/comments", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setcommentdata(response.data);
      })
      .catch((error) =>
        console.error("Error fetching account creation data:", error)
      );
  }, []);

  return (
    <div
      style={{ display: "flex", height: "100vh", backgroundColor: "#0b0b0b" }}
    >
      <div
        style={{
          width: "250px",
          backgroundColor: "#0b0b0b",
          height: "94vh",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            width: "100px",
            height: "100px",
            position: "absolute",
            top: "20px",
            left: "30px",
          }}
        />
        <div
          style={{
            width: "100px",
            height: "100px",
            marginBottom: "30px",
          }}
        />
        <button onClick={() => navigate("/dashboard")} style={buttonStyle}>
          Dashboard
        </button>
        <button onClick={() => navigate("/analytics")} style={buttonStyle}>
          Analytics
        </button>
      </div>

      <div
        style={{
          flex: 1,
          padding: "20px",
          backgroundColor: "#1f1f1f",
          color: "white",
        }}
      >
        <div style={{ margin: "auto" }}>
          <h2>Dashboard:</h2>
          <div
            style={{
              marginTop: "auto",
              display: "flex",
              flexDirection: "row",
              padding: "10px",
              margin: "auto",
              width: "99%",

              // background: "linear-gradient(to bottom right, #b0b0b0, #b0b0b0)",
              clipPath: "polygon(0 0, 100% 0, 90% 100%, 0% 100%)",
              WebkitClipPath:
                "polygon(0 0, 100% 0, 100% 100%, 0% 100%)" /* For Safari */,

              WebkitBackdropFilter: "blur(10px)" /* For Safari */,
              // borderRadius: "25px",
              //boxShadow: "0px 0px 100px rgba(9, 9, 9, 0.8)",

              backgroundColor: "#b0b0b0",
              borderRadius: "15px",
              backdropFilter: "blur(20px)", // Adjust the blur strength as needed
            }}
          >
            <div
              style={{
                flex: 1,
                padding: "10px",
                //backgroundColor: "#bdbdbd",
                color: "black",
              }}
            >
              <h3>Total Users: {loginData}</h3>
            </div>
            <div
              style={{
                flex: 1,
                padding: "10px",

                color: "black",
              }}
            >
              <h3>Videos on Wipe: {accountData}</h3>
            </div>
            <div
              style={{
                flex: 1,
                padding: "10px",

                color: "black",
              }}
            >
              <h3>Comments on Wipe: {commentdata}</h3>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "24px",
              flexDirection: "row",
              padding: "10px",
              marginRight: "auto",
              marginLeft: "auto",
              width: "99%",

              // background: "linear-gradient(to bottom right, #b0b0b0, #b0b0b0)",
              clipPath: "polygon(0 0, 100% 0, 90% 100%, 0% 100%)",
              WebkitClipPath:
                "polygon(0 0, 100% 0, 100% 100%, 0% 100%)" /* For Safari */,

              WebkitBackdropFilter: "blur(10px)" /* For Safari */,
              // borderRadius: "25px",
              //boxShadow: "0px 0px 100px rgba(9, 9, 9, 0.8)",

              backgroundColor: "#0b0b0b",
              borderRadius: "15px",
              backdropFilter: "blur(20px)", // Adjust the blur strength as needed
            }}
          >
            <div
              style={{
                padding: "10px",
                //backgroundColor: "#bdbdbd",
                color: "white",
              }}
            >
              <h3>Api Status:</h3>
            </div>
            <div
              style={{
                padding: "10px",
                alignSelf: "flex-end",
                //backgroundColor: "#bdbdbd",
                color: "#34eb4c",
              }}
            >
              {commentdata ? (
                <h3>Online</h3>
              ) : (
                <h3
                  style={{
                    alignSelf: "flex-end",
                    //backgroundColor: "#bdbdbd",
                    color: "#f52e20",
                  }}
                >
                  Offline
                </h3>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
