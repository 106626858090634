import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import logo from "./Wipeit(4).png"; // Import your logo image
import logo2 from "./wipegray.png"; // Import your logo image

import { useNavigate } from "react-router-dom";

const Analytics = () => {
  const navigate = useNavigate();
  const buttonStyle = {
    backgroundColor: "#1c1c1c",
    color: "#f1f1f1",
    padding: "15px",
    borderRadius: "10px",
    border: "none",
    cursor: "pointer",
    fontWeight: "bold",
    width: "100%",
    marginBottom: "10px", // Space between buttons
    boxSizing: "border-box",
  };
  const [loginData, setLoginData] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    axios
      .get("https://api.wipe.wtf/stats/logins-last-year", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoginData(response.data);
      })
      .catch((error) => console.error("Error fetching login data:", error));

    axios
      .get("https://api.wipe.wtf/stats/accounts-created-last-year", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAccountData(response.data);
      })
      .catch((error) =>
        console.error("Error fetching account creation data:", error)
      );
  }, []);

  useEffect(() => {
    if (loginData.length > 0 && accountData.length > 0) {
      setLoading(false);
    }
  }, [loginData, accountData]);

  if (loading) {
    return (
      <div
        style={{ display: "flex", height: "100vh", backgroundColor: "#0b0b0b" }}
      >
        <div
          style={{
            width: "250px",
            // backgroundColor: "red",
            height: "94vh",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="Logo"
            style={{
              width: "100px",
              height: "100px",
              position: "absolute",
              top: "20px",
              left: "30px",
            }}
          />
          <div
            style={{
              width: "100px",
              height: "100px",
              marginBottom: "30px",
            }}
          />
          <button onClick={() => navigate("/dashboard")} style={buttonStyle}>
            Dashboard
          </button>
          <button onClick={() => navigate("/analytics")} style={buttonStyle}>
            Analytics
          </button>
        </div>
        <div
          style={{
            flex: 1,
            padding: "20px",
            backgroundColor: "#1f1f1f",
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              position: "relative",
              backgroundColor: "#1f1f1f",
              //width: "100vh",
              height: "100vh",
            }}
          >
            <div
              style={{
                margin: "auto",
              }}
            >
              <img
                src={logo2}
                alt="Logo"
                style={{
                  height: "100px",
                  width: "100px",

                  margin: "auto",
                }}
              />
              <h4
                style={{
                  width: "100px",
                  height: "100px",
                  margin: "auto",
                  color: "white",
                  alignSelf: "center",
                  textAlign: "center",
                }}
              >
                loading...
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Function to generate dates between start and end dates
  const getDatesBetween = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  // Extract dates from the login data
  const loginDates = loginData.map((item) => new Date(item.day));

  // Find the earliest date
  const minLoginDate = new Date(Math.min(...loginDates));

  // Extract dates from the account data
  const accountDates = accountData.map((item) => new Date(item.day));

  // Find the earliest date
  const minAccountDate = new Date(Math.min(...accountDates));

  // Find the earliest overall date
  const minDate = new Date(Math.min(minLoginDate, minAccountDate));

  // Generate dates between the earliest date and today
  const today = new Date();
  const datesRange = getDatesBetween(minDate, today);

  // Create a map of login data by date
  const loginDataMap = Object.fromEntries(
    loginData.map((item) => [new Date(item.day), item.loginCount])
  );

  // Create a map of account data by date
  const accountDataMap = Object.fromEntries(
    accountData.map((item) => [new Date(item.day), item.count])
  );

  // Fill in missing dates with zero counts for login data
  const loginDataFilled = datesRange.map((date) => ({
    day: date.toISOString().split("T")[0],
    loginCount: loginDataMap[date] || 0,
  }));

  // Fill in missing dates with zero counts for account data
  const accountDataFilled = datesRange.map((date) => ({
    day: date.toISOString().split("T")[0],
    count: accountDataMap[date] || 0,
  }));

  const loginOptions = {
    chart: {
      backgroundColor: "#1f1f1f", // Set background color to grey
      borderRadius: 0, // Add border radius for material look
      shadow: false, // Add shadow for material look
      //spacing: 60,
    },
    title: {
      text: "",
      align: "center",
      lineWidth: 0,
      style: {
        color: "#fff", // Title text color
        fontSize: "18px", // Title font size
        fontWeight: "400",
      },
    },

    xAxis: {
      gridLineColor: "red",
      type: "category",

      title: {
        text: "",
      },
      categories: loginDataFilled.map((item) => item.day),
    },
    yAxis: {
      gridLineColor: "#454545",
      title: {
        text: "Logins:",
        style: {
          color: "#fff", // Y-axis title color
          fontSize: "16px", // Y-axis title font size
        },
      },
      style: {
        color: "#0b0b0b", // Y-axis title color
        fontSize: "16px", // Y-axis title font size
      },
    },
    series: [
      {
        name: "Logins",
        data: loginDataFilled.map((item) => item.loginCount),
        color: "#fff", // Series color
      },
    ],
    credits: {
      enabled: false, // Disable credits (watermark)
    },
  };

  const accountOptions = {
    chart: {
      backgroundColor: "#1f1f1f", // Set background color to grey
      borderRadius: 0, // Add border radius for material look
      shadow: false, // Add shadow for material look
      plotShadow: false, // Disable shadows
      //spacing: 60,
    },
    title: {
      text: "",
      align: "center",
      style: {
        color: "#fff", // Title text color
        fontSize: "18px", // Title font size
        fontWeight: "400",
      },
    },
    xAxis: {
      lineColor: "rgba(31, 31, 31, 0.8)",
      type: "category",
      title: {
        text: "",

        style: {
          color: "#0b0b0b", // X-axis title color
          fontSize: "16px", // X-axis title font size
        },
      },
      categories: accountDataFilled.map((item) => item.day),
    },
    yAxis: {
      gridLineColor: "#454545",
      lineColor: "red",
      title: {
        text: "Accounts:",

        style: {
          color: "#fff", // Y-axis title color
          fontSize: "16px", // Y-axis title font size
        },
      },
    },
    series: [
      {
        name: "Accounts:",
        data: accountDataFilled.map((item) => item.count),
        color: "#fff", // Series color
      },
    ],
    credits: {
      enabled: false, // Disable credits (watermark)
    },
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        flexDirection: "column",
        backgroundColor: "#0b0b0b",
      }}
    >
      <img
        src={logo}
        alt="Logo"
        style={{
          width: "100px",
          height: "100px",
          position: "absolute",
          top: "20px",
          left: "30px",
          zIndex: 2,
        }}
      />
      <div style={{ display: "flex", height: "100vh" }}>
        <div
          style={{
            width: "250px",
            backgroundColor: "#0b0b0b",
            height: "80vh",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="Logo"
            style={{
              width: "100px",
              height: "100px",
              position: "absolute",
              top: "20px",
              left: "30px",
            }}
          />
          <div
            style={{
              width: "100px",
              height: "100px",
              marginBottom: "30px",
            }}
          />
          <button onClick={() => navigate("/dashboard")} style={buttonStyle}>
            Dashboard
          </button>
          <button onClick={() => navigate("/analytics")} style={buttonStyle}>
            Analytics
          </button>
        </div>
        <div
          style={{
            flex: 1,
            padding: "20px",
            backgroundColor: "#1f1f1f",
            color: "white",
          }}
        >
          <h2 style={{ height: "10vh" }}>Analytics:</h2>
          <div
            style={{
              width: "100%",
              height: "40%",
              float: "left",

              backgroundColor: "#0b0b0b",
            }}
          >
            <HighchartsReact
              highcharts={Highcharts}
              options={loginOptions}
              containerProps={{ style: { height: "40vh" } }}
            />
          </div>
          <div
            style={{
              width: "100%",
              height: "40%",
              float: "left",
              backgroundColor: "#0b0b0b",
            }}
          >
            <HighchartsReact
              highcharts={Highcharts}
              containerProps={{ style: { height: "40vh" } }}
              options={accountOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
